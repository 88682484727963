<template>
  <div id="OrderCenter">
    <el-card>

      <div class="AdminTopOper">
        <el-space wrap :size="15">
          <el-input v-model="pageData.searchKey" placeholder="请输入订单号"></el-input>
          <el-input style="width: 120px" v-model="pageData.where.id" placeholder="请输入ID"></el-input>
          <el-button type="primary" icon="Search" @click="orderSearce()">搜索</el-button>
          <!-- <el-button v-if="showNew" type="primary" icon="Plus" @click="pageData.handleCreate()">新建</el-button> -->
          <el-select v-model="pageData.where.status" placeholder="选择订单状态" @change="change">
            <el-option v-for="orderStatus in initData.orderStatus" :key="orderStatus.status" :label="orderStatus.name"
              :value="orderStatus.status" />
          </el-select>

          <el-button @click="click_exportAddress()">导出地址excel</el-button>
          <!-- <el-button @click="click_AllorderFile()">批量下载文件</el-button> -->

          <el-select v-model="PrinterComposeActiveValue" placeholder="Select" size="large" @change="PrinterComposeChange">
            <el-option v-for="PrinterCompose in PrinterComposeList" :key="PrinterCompose.text"
              :label="PrinterCompose.text" :value="PrinterCompose.text" />
          </el-select>

        </el-space>
      </div>


      <v-contextmenu ref="contextmenu">
        <v-contextmenu-item @click="contextmenu_down()"> <el-icon class="el_icon">
            <Download />
          </el-icon>下载文件</v-contextmenu-item>
        <v-contextmenu-item @click="click_toPdf()" v-if="rightActiveFile.type != 'pdf'"> <el-icon class="el_icon">
            <CopyDocument />
          </el-icon>转换为PDF</v-contextmenu-item>

        <v-contextmenu-item @click="click_getFileInfo()"> <el-icon class="el_icon">
            <Tickets />
          </el-icon>获取文件信息</v-contextmenu-item>

        <v-contextmenu-item v-if="checkOne.moreFileOp" @click="click_EditFile()"> <el-icon class="el_icon">
            <Edit />
          </el-icon>编辑文件</v-contextmenu-item>

        <v-contextmenu-item v-if="checkOne.moreFileOp" @click="click_deleteFile()"> <el-icon class="el_icon">
            <Delete />
          </el-icon>删除文件</v-contextmenu-item>

        <v-contextmenu-item @click="click_oepnFile()"> <el-icon class="el_icon">
            <Reading />
          </el-icon>打开文件</v-contextmenu-item>
        <v-contextmenu-item @click="click_oepnFolder()"> <el-icon class="el_icon">
            <FolderOpened />
          </el-icon>打开文件夹</v-contextmenu-item>
        <v-contextmenu-item @click="click_printCover()" v-if="rightActiveFile.binding.indexOf('胶装') > 0"> <el-icon
            class="el_icon">
            <Printer />
          </el-icon>打印封面</v-contextmenu-item>
        <v-contextmenu-item @click="click_printDo()"> <el-icon class="el_icon">
            <Printer />
          </el-icon>打印</v-contextmenu-item>
      </v-contextmenu>

      <div class="outBox" v-loading="store.state.pageLoading">
        <div class="OrderOutBox">

          <div v-for="Order in pageData.rows" :key="Order.id" @click="clickOrderOne(Order)">
            <a href="javascript:void(0);">
              <div :class="{ OrderActive: OrderActive == Order.id, Order: true, smallFont: true }" id="OrderId">
                <span class="smallFont">{{ Order.number }} </span> <span class="smallFont">【{{ refererName(Order)
                }}】</span>
                <div style="display: flex;line-height: 35px;margin-bottom: 6px;">
                  <div>
                    {{ Order.file_count }} 个文件， <span class="rmb">{{ Order.amount ? Math.round(Order.amount * 100) / 100
                      :
                      0 }}</span><span class="smallFont">元</span>
                  </div>

                  <div style="margin-left: 20px;">
                    <div v-if="Order.status == 10">待付款</div>
                    <div v-if="Order.status == 20 && Order.printing != 1">
                      <el-tag size="default" type="warning" effect="dark">待打印</el-tag>
                    </div>
                    <div v-if="Order.status == 30">
                      <el-tag type="success" effect="dark">待收货</el-tag>
                    </div>
                    <div v-if="Order.status == 50">
                      <el-tag type="success">订单完成</el-tag>
                    </div>
                    <div v-if="Order.printing == 1 && Order.status == 20">
                      <el-tag type="warning">开始打印</el-tag>
                    </div>
                  </div>
                </div>
                <div>
                  <div>ID：{{ Order.userId }}，{{ Order.username }}，{{ JSON.parse(Order.addressJSON).receiver }}</div>
                </div>

                <div>
                  <div> {{ Order.createdAt }} </div>
                </div>
              </div>
            </a>

          </div>

        </div>

        <div class="orderDetail">

          <div class="buttonBox">
            <el-button @click="click_downFileAll()">下载文件</el-button>
            <!-- <el-button @click="click_toPdfAll()">转换文件</el-button> -->
            <el-button @click="click_printList()">打印清单</el-button>


            <el-button @click="click_getFileInfoAll()">获取文件信息</el-button>
            <el-button @click="click_printFileAll()" :loading="loading.Printing">打印文件(all)</el-button>
            <el-button @click="click_printExpress()" :loading="printExpress_loading">快递单</el-button>
            <div style="margin-left: 2px;margin-top:8px;">
              <el-checkbox v-model="checkOne.tips">打印提示</el-checkbox>

              <el-checkbox v-model="checkOne.OrderDetails">订单详情</el-checkbox>
              <!-- <el-checkbox v-model="checkOne.OpRecordList">打印清单</el-checkbox> -->
              <el-checkbox v-model="checkOne.fileFilter">文件分类</el-checkbox>
              <el-checkbox v-model="checkOne.ziliku" v-if="Order.UpFilesOther">资料库</el-checkbox>
              <el-checkbox v-model="checkOne.moreFileOp">更多选项</el-checkbox>
            </div>
          </div>

          <div class="buttonBox" v-if="false">

          </div>

          <div class="buttonBox tips" v-if="checkOne.tips">
            预计用纸：{{ getTips().needPaperCount }} 张 。 系统提示：{{ getTips().tips }}
            <div style="margin-left: 2px;margin-top:8px;" v-if="checkOne.fileFilter">
              <el-checkbox v-model="checkOne.F_SINGLE" v-if="checkOne.show_SINGLE">单面</el-checkbox>
              <el-checkbox v-model="checkOne.F_DOUBLE" v-if="checkOne.show_DOUBLE">双面</el-checkbox>
              <el-checkbox v-model="checkOne.F_G80" v-if="checkOne.show_G80">80g</el-checkbox>
              <el-checkbox v-model="checkOne.F_BIND2" v-if="checkOne.show_BIND2">胶装/铁圈装</el-checkbox>
              <el-checkbox v-model="checkOne.F_A3" v-if="checkOne.show_A3">A3纸</el-checkbox>
              <el-checkbox v-model="checkOne.F_A4" v-if="checkOne.show_A4">A4纸</el-checkbox>
              <el-checkbox v-model="checkOne.F_B5" v-if="checkOne.show_B5">B5纸</el-checkbox>
              <el-checkbox v-model="checkOne.F_COLOR" v-if="checkOne.show_COLOR">彩色</el-checkbox>
              <el-checkbox v-model="checkOne.F_COLOR2" v-if="checkOne.show_COLOR2">激光彩色</el-checkbox>
              <el-checkbox v-model="checkOne.F_NOBIND" v-if="checkOne.show_NOBIND">不装订</el-checkbox>
              <el-checkbox v-model="checkOne.F_BIND" v-if="checkOne.show_BIND">订书针</el-checkbox>
            </div>

          </div>

          <div class="buttonBox tips" v-if="checkOne.OrderDetails">
            <OrderInfoDetail :Order="Order"> </OrderInfoDetail>
          </div>


          <div class="buttonBox tips upfileBox" v-if="checkOne.ziliku && Order.UpFilesOther">
            <div v-for="(upfile, index) in Order.upFiles" class="upfile">
              <img :src="upfile.imgUrl" >
              <div>
                {{ upfile.filename }}

                <div class="printAtt2">
                  {{ upfile.printAtt2 }}
                </div>
                <div class="printAtt">
                  {{ upfile.printAtt }} 【{{ upfile.count }} 份】
                </div>
                <div class="printAtt">
                  编号：{{ index + 1 }}
                </div>
                {{ upfile.price * upfile.count }} 元
              </div>
            </div>
          </div>

          <div class="fileOutBox2">
            <div class="fileOutBox">

              <!-- <div class="fileBox" v-for="i in (Order.file_count > 4 ? 4 : Order.file_count)" v-if="fileBoxLoading">

              </div> -->


              <div v-for="File, index in Order.Files" :key="File.id" @click="click_fileOutBox(File)">

                <div :class="{ fileBox: true, fileBoxACtive: true }" v-loading="File.actvieMessage"
                  :element-loading-text="File.actvieMessage" v-contextmenu:contextmenu
                  @contextmenu.prevent.stop="fileBox_rightClick(File)">

                  <div class="filename">
                    <img src="../assets/img/pdf.png" alt="" v-if="File.type == 'pdf'">
                    <img src="../assets/img/word.png" alt="" v-if="File.type == 'word'">
                    <img src="../assets/img/ppt.png" alt="" v-if="File.type == 'ppt'">
                    <img :src="File.url" alt="" v-if="File.type == 'img'">
                    <div>
                      <div class="filenameText">
                        {{ File.oldfilename }}
                      </div>
                      <div class="filenameTextBottom">
                        （ <span class="strong"> {{ index + 1 }} </span> ）
                        <!-- 【{{ 2.6 }} M】 【PCS】  -->
                        <span :class="{ strong: File.pageCount > 10 }">【 {{ File.pageCount }} 页 】</span> 【 {{
                          File.totalPrice }} 元 】 <el-tag v-if="File.hasPdf >= 1" size="default" type="success"
                          style="margin-left: 6px;">PDF</el-tag>
                      </div>
                    </div>
                  </div>

                  <div class="printParams">

                    <div>
                      <text :class='{ double: File.double == "双面", strong: File.double != "双面" }'> {{ File.double
                      }}</text>
                      <text class='fenge'> 丨 </text>
                    </div>
                    <div>
                      <div :class="{ colorFont: File.style != '黑白' }">{{ File.style }} <text class='fenge'> 丨 </text>
                      </div>
                    </div>
                    <div :class="{ strong: File.size != 'A4' }"> {{ File.size }} </div>
                    <text class='fenge'> 丨 </text>
                    <div :class="{ strong: File.material != '70g复印纸' }"> {{ File.material }} </div>
                    <text class='fenge'> 丨 </text>
                    <div :class="{ strong: File.binding != '不装订' && File.binding != '订书针' }">
                      {{ File.binding }} <text class='fenge'> 丨 </text>
                    </div>
                    <div v-if="File.scale != '每版打印1页'" class="strong"> {{ File.scale }} <text class='fenge'> 丨 </text>
                    </div>

                    <div v-if="File.binding == '皮纹纸胶装'">
                      <div> {{ File.coverColor }} <text class='fenge'> 丨 </text></div>
                    </div>
                    <div v-if="File.binding == '皮纹纸胶装'">
                      <div> {{ File.coverContent }} <text class='fenge'> 丨 </text> </div>
                    </div>
                    <div v-if="File.binding == '皮纹纸胶装' && File.coverContent == '文字封面'">
                      <div> {{ File.coverText }} <text class='fenge'> 丨 </text> </div>
                    </div>
                    <div :class="{ strong: File.count != 1 }">
                      【 <text :class='{ PageCount: File.count != 1, PageCount2: true }'> {{ File.count }} </text> 份】
                      <text class='fenge'> </text>
                    </div>
                  </div>

                  <div class="filenameTextBottom">
                    <span> 【 {{ (Math.round(File.fileSize / 100000) / 10) }} m 】</span>
                    <span> 【 {{ File.uploadMethod }} 】</span>
                    <el-tag v-if="File.filePath" size="default">已下载</el-tag>
                    <el-tag v-if="File.dmOrientation == 1" size="default" type="info"
                      style="margin-left: 6px;">竖向</el-tag>
                    <el-tag v-if="File.dmOrientation == 2" size="default" type="warning"
                      style="margin-left: 6px;">横向</el-tag>
                    <el-tag v-if="File.printed >= 1" size="default" type="success" style="margin-left: 6px;">打印完成</el-tag>
                    <el-tag v-if="File.printed == -1" size="default" type="danger" style="margin-left: 6px;">打印失败</el-tag>
                    <el-tag v-if="File.isPassword" size="default" type="warning" style="margin-left: 6px;">文件加密</el-tag>
                    <el-tag v-if="File.hasPdf == -1" size="default" type="danger" style="margin-left: 6px;">转换失败</el-tag>
                  </div>

                  <div v-if="false">
                    {{ File.filePath }}
                  </div>
                </div>



              </div>

            </div>
          </div>




        </div>
      </div>

      <div v-if="false">
        <el-button @click="clickAll('MarkAsPrinted')">未发货订单设置为开始打印</el-button>

        <el-button @click="clickAll('MarkAsPrint')">未发货订单设置为未打印</el-button>
      </div>

      <AdminPagination :showEdit="false"> </AdminPagination>

    </el-card>

    <PrintDom :Order="Order" ref="PrintDomComponent"> </PrintDom>

    <!-- 编辑单个文件 -->
    <el-dialog v-model="fileEditDialogVisible" title="编辑文件" width="600px" align-center>
      <el-form label-width="100px" :model="rightActiveFile" style="max-width: 460px">
        <el-form-item label="文件名">
          <el-input v-model="rightActiveFile.oldfilename" clearable />
        </el-form-item>
        <el-form-item label="打印份数">
          <el-input v-model="rightActiveFile.count" clearable />
        </el-form-item>
        <el-form-item label="颜色">
          <el-select v-model="rightActiveFile.style" placeholder="" clearable>
            <el-option label="黑白" value="黑白" />
            <el-option label="彩色" value="彩色" />
            <el-option label="激光彩色" value="激光彩色" />
          </el-select>
        </el-form-item>
        <el-form-item label="单双">
          <el-select v-model="rightActiveFile.double" placeholder="" clearable>
            <el-option label="单面" value="单面" />
            <el-option label="双面" value="双面" />
          </el-select>
        </el-form-item>
        <el-form-item label="纸张大小">
          <el-select v-model="rightActiveFile.size" placeholder="" clearable>
            <el-option label="A4" value="A4" />
            <el-option label="A3" value="A3" />
            <el-option label="B5" value="B5" />
          </el-select>
        </el-form-item>
        <el-form-item label="打印方向">
          <el-select v-model="rightActiveFile.dmOrientation" placeholder="" clearable>
            <el-option label="竖向" :value="1" />
            <el-option label="横向" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="打印范围">
          <el-row :gutter="20">
            <el-col :span="9">
              <el-input v-model="rightActiveFile.pageMin" clearable />
            </el-col>
            <el-col :span="1">
              -
            </el-col>
            <el-col :span="9">

              <el-input v-model="rightActiveFile.pageMax" clearable />
            </el-col>
            <el-col :span="4">
              <el-checkbox v-model="rightActiveFile.range2" :true-label="1" :false-label="0">选页码</el-checkbox>
            </el-col>


          </el-row>


        </el-form-item>
        <el-form-item label="装订方式">
          <el-select v-model="rightActiveFile.binding" placeholder="请选择装订方式" clearable>
            <el-option label="不装订" value="不装订" />
            <el-option label="订书针" value="订书针" />
            <el-option label="皮纹纸胶装" value="皮纹纸胶装" />
            <el-option label="铁圈装" value="铁圈装" />
          </el-select>
        </el-form-item>
        <el-form-item label="封面内容" v-if="rightActiveFile.binding == '皮纹纸胶装'">
          <el-select v-model="rightActiveFile.coverContent" placeholder="" clearable>
            <el-option label="文件首页为封面" value="文件首页为封面" />
            <el-option label="文字封面" value="文字封面" />
            <el-option label="纯色封面" value="纯色封面" />
          </el-select>
        </el-form-item>
        <el-form-item label="封面文字" v-if="rightActiveFile.binding == '皮纹纸胶装' && rightActiveFile.coverContent == '文字封面'">
          <el-input v-model="rightActiveFile.coverText" clearable />
        </el-form-item>



      </el-form>
      <template #footer>
        <span class="dialog-footer">


          <el-button @click="fileEditDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="fileEditDialogVisible = false">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
<script setup >
import { onMounted, onUpdated, reactive, ref, watch } from 'vue'
import Api from '@/network'
import OrderInfoDetail from "./adminComponents/orderInfoDetail"
import PrintDom from "./adminComponents/PrintDom"
import { request } from '@/network/request'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '../store'
import { ElNotification } from 'element-plus'
import { sync } from 'glob'
import { resolve } from 'path'
import WsPrint from './adminComponents/wsPrint.js'
let fileProgram = ref(true)
const router = useRouter()
const pageData = reactive(Api.adminPage('order'))
pageData.count = 6
pageData.limit = 6
pageData.where.status = 20
//pageData.where.pageType = 'noFile'
pageData.where.pageType2 = 'noStatus'
let OrderActive = ref(0)
let Order = ref({})
let printFiles = ref([])
let duration = ref(1000 * 20)
let rightActiveFile = ref({})
let loading = ref({ Printing: false })
let storagePath = "C:\\prePrint2"
let checkOne = ref({ tips: true, OrderDetails: false, OpRecord: false, OpRecordList: false, moreFileOp: false, ziliku: true })
let fileBoxLoading = ref(false)
let fileEditDialogVisible = ref(false)
onMounted(async () => {
  await pageData.getPage()
  OrderActive.value = pageData.rows[0].id
  Order.value = pageData.rows[0]
  clickOrderOne(Order.value)
})


const PrinterComposeList = [
  {
    text: "黑白2号|彩色1号",
    black_printer1: { printerName: '黑白2号' },
    black_printer2: { printerName: '黑白2号' },
    black_printer3: { printerName: '黑白2号' },
    color_printer1: { printerName: '彩色1号' },
    color_printer2: { printerName: '激光彩色1号' },
  },

  {
    text: "黑白1号|彩色2号",
    black_printer1: { printerName: '黑白1号' },
    black_printer2: { printerName: '黑白1号(左侧双钉)' },
    black_printer3: { printerName: '黑白1号(横向角钉)' },
    color_printer1: { printerName: '彩色2号' },
    color_printer2: { printerName: '激光彩色1号' },
  },
  {
    text: "黑白3号|彩色1号",
    black_printer1: { printerName: '黑白3号' },
    black_printer2: { printerName: '黑白3号(左侧双钉)' },
    black_printer3: { printerName: '黑白3号(横向角钉)' },
    color_printer1: { printerName: '彩色1号' },
    color_printer2: { printerName: '激光彩色1号' },
  },
  {
    text: "黑白4号|彩色2号",
    black_printer1: { printerName: '黑白4号' },
    black_printer2: { printerName: '黑白4号' },
    black_printer3: { printerName: '黑白4号' },
    color_printer1: { printerName: '彩色2号' },
    color_printer2: { printerName: '激光彩色1号' },
  },
  {
    text: "黑白2号|彩色3号",
    black_printer1: { printerName: '黑白2号' },
    black_printer2: { printerName: '黑白2号' },
    black_printer3: { printerName: '黑白2号' },
    color_printer1: { printerName: '彩色3号' },
    color_printer2: { printerName: '激光彩色1号' },
  },
  {
    text: "黑白1号|彩色3号",
    black_printer1: { printerName: '黑白1号' },
    black_printer2: { printerName: '黑白1号(左侧双钉)' },
    black_printer3: { printerName: '黑白1号(横向角钉)' },
    color_printer1: { printerName: '彩色3号' },
    color_printer2: { printerName: '激光彩色1号' },
  }
]
let PrinterComposeActive = ref(PrinterComposeList[0])
let PrinterComposeActiveValue = ref(PrinterComposeList[0].text)
const PrinterComposeChange = () => {
  PrinterComposeActive.value = PrinterComposeList.filter(i => i.text == PrinterComposeActiveValue.value)[0]
}
// watch(pageData.offset, (newValue, oldValue) => {
// })
const initData = ref(JSON.parse(localStorage.getItem('initData')))
const clickAll = (type) => {
  Api.request({
    url: '/admin/order/adminDataHandle',
    method: 'post',
    data: {
      type: type
    }
  })
}
const refererName = (row) => {
  if (!row.referer) row.referer = ''
  for (let mini of store.state.initData.wxMiniProgram_list) {
    if (row.referer.indexOf(mini.appid) > 0) {
      return mini.name
    }
  }
  return "博立云印"
}

const clickOrderOne = async (order) => {
  console.log(order)
  OrderActive.value = order.id
  Order.value = order

  // fileBoxLoading.value = true
  // let fileRes = await getFileList(Order.value.id)
  // if (fileRes[0].OrderId == Order.value.id) {
  //   Order.value.Files = fileRes
  //   console.log(Order.value.Files)
  // }
  // fileBoxLoading.value = false

  await getFileFilter(Order.value)
}


const fileBox_rightClick = (File) => {
  rightActiveFile.value = File
}
const click_downFileAll = async () => {
  let isPcS = false
  for (let file of Order.value.Files) {
    if (file.uploadMethod == 'pcs') { isPcS = true }
  }
  if (isPcS && !Order.value.up_pcs) { await update_psc() }
  let time = 0
  for (let File of Order.value.Files) {
    setTimeout(() => { contextmenu_down(File) }, time)
    time += File.fileSize / 1000 / 30
  }
  ElNotification({ title: 'Success', message: "全部文件下载完毕\n" + Order.value.number, type: 'success', duration: 30000 })
}
const click_printList = async () => {
  document.getElementById("printList").click();
  ElNotification({ title: 'Success', message: "打印清单\n" + Order.value.number, type: 'success', duration: 5000 })
}


const click_printFileAll = async () => {
  loading.value.Printing = true

  if (checkOne.value.OpRecordList) {
    await store.state.exportPNG()
  }
  // ===================
  for (let File of Order.value.Files) {
    await click_printDo(File)
    removeFile(1)
  }
  loading.value.Printing = false

  ElNotification({ title: 'Success', message: "全部文件打印完毕\n" + Order.value.number, type: 'success', duration: 30000 })
}
const click_toPdfAll = async () => {
  for (let File of Order.value.Files) {
    if (File.type != "pdf") { await click_toPdf(File) }
  }
  ElNotification({ title: 'Success', message: "全部文件转换完毕\n" + Order.value.number, type: 'success', duration: 30000 })
}
const click_getFileInfoAll = async () => {
  for (let File of Order.value.Files) {
    await click_getFileInfo(File)
  }
  ElNotification({ title: 'Success', message: "全部文件信息获取成功\n" + Order.value.number, type: 'success', duration: 30000 })
}

const click_fileOutBox = async (File) => {
  return
  let Path = File.filePath
  let res = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'GET',
    params: {
      Path: escape(Path),
      //  deepDir: escape(JSON.stringify(deepDir)),
      operation: "openDocument",
    }
  })
}
const click_oepnFile = async (File) => {
  if (!File) File = rightActiveFile.value
  if (!File.filePath) {
    await contextmenu_down(File)
  }
  let Path = File.filePath
  let res = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'GET',
    params: {
      Path: escape(Path),
      //  deepDir: escape(JSON.stringify(deepDir)),
      operation: "openDocument",
    }
  })


}
const click_oepnFolder = async () => {
  let res = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'GET',
    params: {
      Path: escape(storagePath + "\\" + Order.value.number),
      //  deepDir: escape(JSON.stringify(deepDir)),
      operation: "openDocument",
    }
  })

}
const click_printCover = async (File) => {
  if (!File) File = rightActiveFile.value
  if (!File.filePath) {
    await contextmenu_down(File)
  }
  File.actvieMessage = "正在打印封面"
  let Path = File.PdfFilePath
  let type = 0
  if (File.coverContent == "文字封面") {
    type = 2
    if (!File.coverText) File.coverText = File.oldfilename.replace('.' + File.Extension, '')
  }
  if (File.coverContent == "文件首页为封面") { type = 3 }
  let res = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'GET',
    params: {
      Path: escape(Path),
      //  deepDir: escape(JSON.stringify(deepDir)),
      operation: "printCover",
      type: type,
      titleText: escape(File.coverText),
      count: escape(File.count)
    }
  })
  File.actvieMessage = false
  return ElNotification({ title: 'Success', message: "打印封面成功", type: 'success', })

}
const click_printDo = async (File, operation) => {
  if (!operation) operation = "printDo"
  if (!File) File = rightActiveFile.value
  if (!fileProgram.value) return ElNotification({ title: 'Error', message: "打印错误 文件处理程序未启动", type: 'error', })
  if (!File.filePath) { await contextmenu_down(File) }
  if (!File.hasInfo) { await click_getFileInfo(File) }




  File.actvieMessage = "正在打印文件"

  if (File.scale != '每版打印1页') {
    File.actvieMessage = false
    File.printed = -1
    return ElNotification({ title: 'Error', message: "多页合一不支持自动打印", type: 'error', duration })
  }
  try {
    let PrintFile = getPrintFile(File)
    let res = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'GET',
      params: {
        FileName: escape(File.PdfFilePath),
        Name: escape(File.filename),
        File: escape(JSON.stringify(PrintFile)),
        operation: operation,
      }
    })
    if (!res.data.code) {
      File.actvieMessage = false
      File.printed = -1
      return ElNotification({ title: 'Error', message: "打印失败 请检查文件", type: 'error', duration })
    }
    File.actvieMessage = false
    File.printed = 2
  } catch (error) {
    File.actvieMessage = false
    return ElNotification({ title: 'Error', message: " 打印错误 文件处理程序未启动", type: 'error', duration })

  }

}
// 转换pdf===================================================================
const click_toPdf = async (File) => {

  if (!File) File = rightActiveFile.value
  if (File.type == "pdf") {
    return;
    return ElNotification({ title: 'Error', message: "PDF文件无需转换", type: 'error', })
  }
  if (!fileProgram.value) return ElNotification({ title: 'Error', message: "转换错误 文件处理程序未启动", type: 'error', })
  if (!File.filePath) {
    console.log(File.filePath)
    await contextmenu_down(File)
  }
  File.actvieMessage = "正在转换PDF"
  let fileToPdfRes = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'GET',
    params: {
      originFilePath: escape(File.filePath),
      fileType: File.type,
      operation: "fileToPdf",
    }
  })
  File.actvieMessage = false
  if (fileToPdfRes.data.code > 0) {
    File.PdfFilePath = fileToPdfRes.data.PdfFilePath
    File.hasPdf = 1
    return ElNotification({ title: 'Success', message: File.filename + " 转换PDF成功", type: 'success', })
  }
  File.hasPdf = -1
  return ElNotification({ title: 'Error', message: "转换PDF失败", type: 'error', })
}
// 获取文件信息===================================================================
const click_getFileInfo = async (File) => {

  if (!File) File = rightActiveFile.value
  if (!fileProgram.value) return ElNotification({ title: 'Error', message: "获取文件信息 文件处理程序未启动", type: 'error', })
  if (!File.filePath) { await contextmenu_down(File) }
  if (!File.PdfFilePath) { await click_toPdf(File) }
  if (File.type == 'img') {
    File.hasInfo = 1
    return File.dmOrientation = 1
  }
  File.actvieMessage = "正在获取文件信息"
  let fileInfoRes = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'GET',
    params: {
      originFilePath: escape(File.PdfFilePath),
      operation: "fileInfo",
    }
  })
  File.actvieMessage = false
  if (fileInfoRes.data.code > 0) {
    if (fileInfoRes.data.code == 2) {
      File.hasInfo = 1
      File.dmOrientation = 1
      File.isPassword = true
      return ElNotification({ title: 'Warning', message: File.filename + "-文件加密", type: 'success', })
    }
    File.hasInfo = 1
    File.dmOrientation = fileInfoRes.data.dmOrientation
    return ElNotification({ title: 'Success', message: File.filename + "获取文件信息成功", type: 'success', })
  }
  File.hasInfo = -1
  return ElNotification({ title: 'Error', message: "获取文件信息失败", type: 'error', })
}

const click_deleteFile = async () => {
  let File2 = rightActiveFile.value

  Order.value.Files = Order.value.Files.filter(i => {
    return i.id != File2.id
  })
  console.log(Order.value.Files)
}
const click_EditFile = async (File) => {
  let File2 = rightActiveFile.value
  File = rightActiveFile.value
  fileEditDialogVisible.value = true


}

const update_psc = async () => {

  let update_dlinkRes = await Api.request({
    url: '/update_dlink',
    method: 'POST',
    data: {
      file_list: Order.value.Files
    }
  })
  console.log(update_dlinkRes.list)
  for (let li of update_dlinkRes.list) {
    for (let file of Order.value.Files) {
      if (file.fs_id == li.fs_id) {
        file.url = li.dlink + `&access_token=${store.state.initData.pcs_accessToken}`
      }
    }
  }
  ElNotification({ title: 'Success', message: "更新网盘下载链接成功", type: 'success', })
  return Order.value.up_pcs = true
}
const contextmenu_down = async (File) => {

  let isPcS = false
  for (let file of Order.value.Files) {
    if (file.uploadMethod == 'pcs') { isPcS = true }
  }
  if (isPcS && !Order.value.up_pcs) { await update_psc() }


  if (!File) File = rightActiveFile.value
  File.actvieMessage = "正在下载文件"
  File.filename = File.filename.replace(`【${File.index + 1}】【${File.pageCount}页】`, "")
  File.filename = `【${File.index + 1}】【${File.pageCount}页】${File.filename}`
  File.filename = invalidPath(File)
  if (File.type == 'img') {
    File.filename = `${File.index + 1}.jpg`
  }
  try {
    let downloadRes = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'GET',
      params: {
        storagePath: escape(storagePath),
        orderNumber: escape(Order.value.number),
        fileJson: escape(JSON.stringify(File)),
        //  deepDir: escape(JSON.stringify(deepDir)),
        operation: "downloadFileOne",

      }
    })
    if (downloadRes.data != "ok") {
      File.actvieMessage = "下载失败"
      setTimeout(() => File.actvieMessage = false, 500)
      return ElNotification({ title: 'Error', message: downloadRes.data, type: 'error', })
    }

    File.filePath = storagePath + "\\" + Order.value.number + "\\" + File.filename
    File.actvieMessage = "下载完毕"
    File.actvieMessage = false
    if (File.type == "pdf") File.PdfFilePath = File.filePath
    // pageData.rows[0].Files[0] = File
    fileProgram.value = true
    return ElNotification({ title: 'Success', message: File.filename + " 下载成功", type: 'success', })
    console.log(downloadRes)
  } catch (error) {
    File.actvieMessage = false
    fileProgram.value = false
    return ElNotification({ title: 'Error', message: " 下载错误 文件处理程序未启动", type: 'error', })

  }
}
const getTips = () => {
  let File_list = Order.value.Files
  if (!File_list) File_list = []
  let paperCount = 0
  let isSingle = false
  let isColor = false
  let colorCount = 0
  let isHighColor = false
  let needBinding = File_list.length
  let singleCount = 0
  let jiaozhuang = 0
  let tiequan = 0
  let ding = 0
  let qimading = 0
  let bindingTips = ''
  let is_scale = false
  let is_noAllPage = false
  let is_80g = false
  let is_A3 = false
  let is_B5 = false
  let is_count = false
  for (let file of File_list) {
    // 预计纸张 是否单面
    let scale = ''
    scale = file.scale.replace('每版打印', '')
    scale = scale.replace('页', '')
    scale = Number(scale)
    if (scale != 1) { is_scale = true }
    if (file.range != '全部') { is_noAllPage = true }
    let pageCount = file.pageCount / scale

    if (file.double == '双面') {
      if (pageCount % 2 != 0) pageCount = pageCount + 1
      paperCount = paperCount + (pageCount / 2) * file.count
    } else {
      paperCount = paperCount + pageCount * file.count
      isSingle = true
      singleCount++
    }
    if (file.style == '彩色') {
      isColor = true
      colorCount++
    }
    if (file.style == '激光彩色') { isHighColor = true }
    if (file.binding == '不装订') { needBinding = needBinding - 1 }
    if (file.binding == '皮纹纸胶装') { jiaozhuang = jiaozhuang + 1 }
    if (file.binding == '铁圈装') { tiequan = tiequan + 1 }
    if (file.binding == '钉书针') { ding = ding + 1 }
    if (file.qimading == '骑马订') { qimading = qimading + 1 }
    if (file.material == '80g复印纸') { is_80g = true }
    if (file.size == 'A3') { is_A3 = true }
    if (file.size == 'B5') { is_B5 = true }
    if (file.count > 1) { is_count = true }
  }
  paperCount = Math.round(paperCount)
  let tips = `${isSingle ? '有单面(' + singleCount + ')，' : ''}${is_80g ? '有80g' : ''}${isColor ? '有彩色(' + colorCount + ')，' : ''}${isHighColor ? '有激光彩色，' : ''}${needBinding ? '需要装订:' + needBinding + '本,' : ''}${is_scale ? '有多页合一，' : ''}${is_noAllPage ? '有选页码打印，' : ''}${is_A3 ? '有A3，' : ''}${is_B5 ? '有B5，' : ''}${is_count ? '有2份以上，' : ''}`
  tips = tips + `。${jiaozhuang ? '胶装:' + jiaozhuang + '本,' : ''}${tiequan ? '铁圈装:' + tiequan + '本,' : ''}${qimading ? '骑马订:' + qimading + '本,' : ''}`
  return { tips, needPaperCount: paperCount }

}
const change = () => {
  pageData.getPage()
}
const getPrintFile = (File) => {
  let { black_printer1, black_printer2, black_printer3, color_printer1, color_printer2 } = PrinterComposeActive.value

  let printer = black_printer1
  let printer2 = black_printer2

  // if (printer.printerName == "黑白3号" && File.binding == "不装订") {
  //   if (File.pageCount >= 60 && File.pageCount <= 100 && File.double == "双面") {
  //     printer = black_printer2
  //   }
  // }
  // if (printer.printerName == "黑白2号" && File.binding == "不装订") {
  //   if (File.pageCount >= 60 && File.pageCount <= 200 && File.double == "双面") {
  //     printer = black_printer2
  //   }
  // }

  if (File.binding == "订书针" && File.style == "黑白") {
    printer = black_printer2
    printer2 = black_printer3
  }
  // if (File.style == "黑白" && File.binding != "订书针" && File.pageCount <= 60) {
  //   printer2 = black_printer1
  //   printer = black_printer1
  // }


  // if (File.binding == "不装订" && File.pageCount >= 35 && File.pageCount < 200) {
  //     printerBlack = "黑白2号(左侧装订)"
  //   //  printerBlack = "黑白1号"
  //   }
  if (File.style == "彩色") { printer = color_printer1; printer2 = color_printer1 }
  if (File.style == "激光彩色") { printer = color_printer2; printer2 = color_printer2 }


  let dmPaperSize = 9
  if (File.size == 'A3') dmPaperSize = 8
  if (File.size == 'B5') dmPaperSize = 13
  if (File.range != "全部" || File.range2 == 1) {
    File.range2 = 1
  } else { File.range2 = 0 }
  File.scaleCount = 1
  if (File.scale != '每版打印1页') {
    File.scaleCount = File.scale.replace("每版打印", "")
    File.scaleCount = File.scaleCount.replace("页", "")
  }
  let objFile = {
    ...File,
    url: File.url,
    Extension: File.Extension,
    dmDuplex: File.double == "单面" ? 1 : 2,
    printer: printer.printerName,
    printer2: printer2.printerName,
    dmPaperSize: dmPaperSize,
    dmCopies: File.count,
  }
  File.printer = printer.printerName
  return objFile
}

const sleep = (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('ok')
    }, time)
  })
}
// 移除文件对象
const removeFile = (fileId) => {
  Order.value.Files = Order.value.Files.filter((item) => {
    return item.id != fileId
  })
}

const invalidPath = (file) => {
  let filename = file.filename
  //  | “?”、“、”、“_”、“/”、“*”、““”、“”“、“<”、“>”、“|”。
  for (let i = 0; i <= 100; i++) {
    filename = filename.replace('\\', '')
      .replace('/', '').replace(':', '').replace('*', '').replace('?', '')
      .replace('、', '').replace('、', '').replace('“', '').replace('|', '')
      .replace('”、', '').replace('<', '').replace('>', '').replace('|', '')
  }
  return filename
}
let printExpress_loading = ref(false)
const click_printExpress = async () => {
  try {
    printExpress_loading.value = true
    let res = await WsPrint.printExpress(Order.value)
    printExpress_loading.value = false
    Order.value.status = 30
  } catch (error) {
    printExpress_loading.value = false
  }

}
const orderSearce = async () => {
  await pageData.handleSearch()
  document.getElementById("OrderId").click();


}


const getFileFilter = async () => {
  checkOne.value = { ...checkOne.value, ...{ F_SINGLE: true, F_DOUBLE: true, F_G80: true, F_BIND2: true, F_A3: true, F_A4: true, F_B5: true, F_COLOR: true, F_COLOR2: true, F_NOBIND: true, F_BIND: true } }

  FileFilterHelp()
  for (let file of Order.value.Files) {
    if (file.double == '双面') checkOne.value.show_DOUBLE = true
    if (file.double == '单面') checkOne.value.show_SINGLE = true
    if (file.style == '彩色') checkOne.value.show_COLOR = true
    if (file.style == '激光彩色') checkOne.value.show_COLOR2 = true
    if (file.binding == '不装订') checkOne.value.show_NOBIND = true
    if (file.binding == '皮纹纸胶装') checkOne.value.show_BIND2 = true
    if (file.binding == '铁圈装') checkOne.value.show_BIND2 = true
    if (file.binding == '钉书针') checkOne.value.show_BIND = true
    if (file.qimading == '骑马订') checkOne.value.show_BIND2 = true
    if (file.material == '80g复印纸') checkOne.value.show_G80 = true
    if (file.size == 'A3') checkOne.value.show_A3 = true
    if (file.size == 'B5') checkOne.value.show_B5 = true
  }
}
const FileFilterHelp = async () => {
  for (let key in checkOne.value) {
    if (key.indexOf('show') >= 0) { checkOne.value[key] = false }
    if (key.indexOf('F_') >= 0) { checkOne.value[key] = true }
  }
}
// ===================数据请求API
const getFileList = async (orderId) => {


  let file_list_all = localStorage.getItem('file_list_all')
  if (!file_list_all) file_list_all = '[]'
  file_list_all = JSON.parse(file_list_all)

  if (file_list_all) {

    let orderFileList = []
    for (let file of file_list_all) {
      if (file.OrderId == orderId) {
        let isRep = false
        for (let orderFile of orderFileList) {
          if (orderFile.id == file.id) isRep = true;
        }
        if (isRep) continue;
        orderFileList.push(file)
      }
    }
    console.log(orderFileList)
    if (orderFileList.length > 0) {

      return orderFileList
    }
  }


  let res = await Api.request({
    url: "/admin/fileApi",
    method: 'post',
    params: {
      method: 'get_page',
      orderId: orderId,
    }
  })

  let file_list = res.data



  file_list_all = file_list_all.concat(file_list)
  // localStorage.setItem('file_list_all', JSON.stringify(file_list_all))

  return file_list
}

</script>
  
  

<style lang="less">
.outBox {
  display: flex;

  //height: 600px;
  //justify-content: space-between;
  .orderDetail {
    background-color: #f5f5f5;
    width: 810px;
    margin: 10px;
    border-radius: 4px;
    height: 630px;
    overflow: auto;
    padding: 15px;
  }
}

.OrderOutBox {
  width: 686px;
  display: flex;
  flex-wrap: wrap;
  height: 680px;
  overflow: auto;

  .Order {
    background-color: rgb(247, 247, 247);
    width: 268px;
    height: 150px;
    margin: 10px 14px;
    border-radius: 8px;
    padding: 20px;
    color: #444;
    font-size: 15px;
    line-height: 30px;

  }

  .Order:hover {
    background-color: rgb(207, 207, 207);
    transform: scale(1.06, 1.06);
    transition: background-color 0.5s, transform 0.2s;
  }
}

.rmb {
  color: #333;
  font-size: 17px;
  font-weight: 700;
  margin-right: 6px;
}

.smallFont {
  font-size: 13px;
  color: #666;
}


.OrderActive {
  background-color: #111 !important;
  color: white !important;
  font-weight: 700;
  //transition:  background-color 2s;

  transform: scale(1.06, 1.06);
  transition: background-color 0.5s, transform 0.2s;

  // transition: color 0.2s;
  .rmb {
    color: white !important;
    font-size: 17px;
    font-weight: 700;
    margin-right: 6px;
    //  transition: color 0.2s;
  }

  .smallFont {
    font-size: 13px;
    color: white !important;
    //  transition: color 0.2s;
  }
}




.fileOutBox2 {
  padding: 10px !important;
  padding-top: 0px !important;
  width: 756px !important;
}

.fileOutBox {
  display: flex;
  flex-wrap: wrap;
  // background-color: rgb(236, 236, 236);
  background-color: white;
  padding: 10px !important;
  width: 756px !important;
  border-radius: 8px;

  //transition: height 0.5s;
  .fileBox {
    width: 336px;
    min-height: 105px;
    background-color: rgb(247, 247, 247);
    // background-color: rgb(248, 248, 248);
    // background-color: white;
    margin: 8px;
    padding: 18px 12px;
    border-radius: 8px;
    font-size: 13px;

    .filename {
      display: flex;
      line-height: 20px;
      height: 42px;
      font-size: 13px;

      .filenameText {
        width: 280px;
        height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .filenameTextBottom {
        margin-top: 7px;
      }

      img {
        margin-right: 5px;
        width: 42px;
        height: 42px;
        border-radius: 4px;
      }
    }
  }

  // .fileBoxACtive{
  //   background-color: rgb(221, 221, 221);
  // }

  .fileBox:hover {
    background-color: rgb(221, 221, 221);
    transform: scale(1.04, 1.04);
    transition: background-color 0.5s, transform 0.2s;
  }

  .printParams {
    display: flex;
    font-size: 12px;
    margin-top: 12px;
    flex-wrap: wrap;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .fenge {
    color: #d3d3d3;
  }

  .strong {
    font-size: 15px !important;
    font-weight: 700;
    color: #5a5a5a;
  }
}

.buttonBox {
  background-color: white;
  padding: 20px;
  margin: 12px;
  border-radius: 8px;
  width: 736px;
}

// 右键菜单属性
.v-contextmenu-item {
  padding: 10px 14px !important;
  line-height: 1;
  color: #333;
  cursor: pointer;
  margin: 7px;
  border-radius: 4px;
}

.v-contextmenu-item:hover {
  padding: 10px 14px !important;
  line-height: 1;
  background-color: rgb(230, 230, 230) !important;
  cursor: pointer;
}

.el_icon {
  font-size: 16px;
  vertical-align: -13%;
  margin-right: 6px;
}

.tips {
  font-size: 14px;
}

.colorFont {
  color: #07c160;
  font-weight: 700;
  font-size: 15px;
}

.AdminTopOper {
  display: flex;
  margin-bottom: 12px;
  margin-left: 10px;
}

.upfileBox {
  display: flex;
}

.upfile {
  background-color: #f5f5f5;
  border-radius: 6px;
  height: auto;
  width: 290px;
  padding: 20px;
  margin: 10px;
  display: flex;
  img{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border-radius: 8px;
  }
}
</style>
  