<template>
    <div>
        <el-descriptions title="订单详情" :column="2">
            <el-descriptions-item label="订单号" :width="desWidth">{{ Order.number }}</el-descriptions-item>
            <el-descriptions-item label="微信交易单号" :width="desWidth2">{{ Order.transaction_id }}</el-descriptions-item>
            <el-descriptions-item label="支付状态" :width="desWidth"> <el-tag type="success"
                    size="small">支付成功</el-tag></el-descriptions-item>
            <el-descriptions-item label="用户名" :width="desWidth2"> {{ Order.username }} </el-descriptions-item>

            <el-descriptions-item label="文件数量" :width="desWidth"> {{ Order.Files.length }} 个 </el-descriptions-item>
            <el-descriptions-item label="订单时间" :width="desWidth2"> {{ Order.createdAt }}   </el-descriptions-item>
           

            <el-descriptions-item label="实付款" :width="desWidth"> {{ Order.amount }} 元  </el-descriptions-item>
            <el-descriptions-item label="快递单号" :width="desWidth2"> {{ Order.expressNumber }} </el-descriptions-item>



            <el-descriptions-item label="收件人姓名" :width="desWidth"> {{ Order.Address.receiver }}</el-descriptions-item>
            <el-descriptions-item label="收件地址" :width="desWidth2">{{ Order.Address.full }} </el-descriptions-item>
            <el-descriptions-item label="收件人手机号" :width="desWidth"> {{ Order.Address.phone }}</el-descriptions-item>
            <el-descriptions-item label="收件详细地址" :width="desWidth2"> {{ Order.Address.detail }}</el-descriptions-item>


        </el-descriptions>

    </div>
</template>
  
<script setup >

const desWidth = 230
const desWidth2 = 500
const props = defineProps({
    Order: { type: Object, default: true },
})
if (!props.Order.Address) props.Order.Address = {}


</script>
  